import React from 'react';
import _ from 'lodash';
import {
    FA_WEALTH,
    MA_WEALTH,
    CHART_LABEL,
    FINANCIAL_ADVISER_ID,
    ACCOUNTANT_ID,
    LEGAL_ADVISER_ID,
    FEE_TYPES,
    ABOUT_URL,
    PROFILE_CHECK_PENDING,
    PROFILE_CHECK_FAIL,
    PROFILE_CHECK_PASS,
    FRIENDLY_NAME, PROFESSIONAL_TYPE,
} from '../__constants__';
import { financial_adviser_services } from '../data/services-financial-adviser';
import { accountant_business_services } from '../data/services-accountant-flat';
import { legal_adviser_services } from '../data/services-legal-adviser-flat';
import { formatPlural, isMortgageAdviser } from '../utils/utilities';

import Typography from '@material-ui/core/Typography';


const criteriaLabel = [
    {
        id: 402,
        label: "Savings/pensions: Any level"
    }, {
        id: 403,
        label: "Savings/pensions: Over £50,000"
    }, {
        id: 404,
        label: "Savings/pensions: Over £100,000"
    }, {
        id: 405,
        label: "Savings/pensions: Over £250,000"
    }, {
        id: 406,
        label: "Savings/pensions: Over £500,000"
    }, {
        id: 408,
        label: "Mortgage value: Any level"
    }, {
        id: 409,
        label: "Mortgage value: Over £100,000"
    }, {
        id: 410,
        label: "Mortgage value: Over £250,000"
    }, {
        id: 411,
        label: "Mortgage value: Over £500,000"
    }, {
        id: 412,
        label: "Mortgage value: Over £1m"
    }, {
        id: 587,
        label: "Mortgage value: Over £50,000"
    },
];

const promotion = [
    {
        id: 415,
        label: "Free 30 minute initial consultation"
    }, {
        id: 416,
        label: "Free one hour initial consultation"
    }, {
        id: 417,
        label: "Up to £100 discount on fees"
    }, {
        id: 418,
        label: "Up to £200 discount on fees"
    }
];

export function getName(name) {
    return name && name.length ? name : 'This individual';
}

export function hasPromotion(itemID) {
    return promotion.find(promotion => {
        if (itemID === promotion.id) {
            return promotion.label;
        } else {
            return ''
        }
    })
}

export function getCriteriaLabel(itemID) {
    return criteriaLabel.find(criteria => itemID === criteria.id)
}

export function getBadgeTitle(item) {
    switch (item.id) {
        case 554:
            return FRIENDLY_NAME.MORTGAGE_ADVISER;
        case 555:
            return FRIENDLY_NAME.MORTGAGE_LIMITED;
        case 550:
            return (item.state === 5 ? FRIENDLY_NAME.FOCUSED : FRIENDLY_NAME.IFA);
        case 551:
            return FRIENDLY_NAME.FA_RESTRICTED;
        default:
            return '';
    }
}

export function getFriendlyNameFromProfessionalType(type) {
    switch (type) {
        case PROFESSIONAL_TYPE.FINANCIAL_COACH:
            return FRIENDLY_NAME.FINANCIAL_COACH;
        case PROFESSIONAL_TYPE.PROTECTION_WHOLE_OF_MARKET:
            return FRIENDLY_NAME.PROTECTION_WHOLE_OF_MARKET;
        case PROFESSIONAL_TYPE.PROTECTION_LIMITED:
            return FRIENDLY_NAME.PROTECTION_LIMITED;
        case PROFESSIONAL_TYPE.EQUITY_RELEASE_WHOLE_OF_MARKET:
            return FRIENDLY_NAME.EQUITY_RELEASE_WHOLE_OF_MARKET;
        case PROFESSIONAL_TYPE.EQUITY_RELEASE_LIMITED:
            return FRIENDLY_NAME.EQUITY_RELEASE_LIMITED;
        default:
            return '';
    }
}

export function offersMortgageService(services, criteria) {
    const offersMortgage = _.find(services, ['id', 392]);
    return !!criteria.label.contains('Mortgage value') && offersMortgage;
}

export function getWealthLevelIds(id, isMortgageAdviser = false) {
    let data = isMortgageAdviser ? MA_WEALTH : FA_WEALTH;

    const ids = Object.entries(data)
        .map(([key, values]) => values['ID'] === id ? values.WEALTH_LEVEL_IDS : undefined)
        .filter(ids => ids !== undefined)
        .slice(0, 1);

    return ids[0] || null;
}

export function getChartData(name, values) {
    let chartData = [];

    if (_.isEmpty(values)) return;

    Object.entries(values).forEach(([key, stats]) => {
        const category = _.get(CHART_LABEL[name], 'categories', null);

        if (!category) return;

        chartData.push({
            name: category[key],
            value: stats,
        });
    });

    return chartData;
}

export function getTooltipTextFCA(hasCheckedStatus, name, authorisedByManager, professionalType) {
    const isProtectionOnly = professionalType === PROFESSIONAL_TYPE.PROTECTION_WHOLE_OF_MARKET || professionalType === PROFESSIONAL_TYPE.PROTECTION_LIMITED;

    if (professionalType === PROFESSIONAL_TYPE.FINANCIAL_COACH ) {
        if (authorisedByManager === true){
            return `${name} does not perform an FCA-regulated role, but ${name} is accredited and monitored by an FCA-authorised firm`;
        }
        return `${name} does not perform an FCA-regulated role, and ${name} is not accredited or monitored by an FCA-authorised firm`;
    }

    if (hasCheckedStatus === true) {
        return `${name} and/or the firm they work for (or are appointed representatives of) is regulated as appropriate by the Financial Conduct Authority`;
    }

    if (authorisedByManager === true) {
        if (isProtectionOnly) {
            return `The firm this adviser works for is FCA regulated and has confirmed that this adviser is authorised to provide protection advice`;
        } else {
            return `${name} is fully authorised under the FCA's Senior Managers & Certification Regime. A regulated Senior Manager has confirmed ${name}'s status`;
        }
    }

    return `Checks for ${name} and/or the firm they work for (or are appointed representatives of) are currently pending`;
}

export function getTooltipTextReviews(hasCheckedStatus, name, reviewCount, reviewVerifiedPercent) {
    if (hasCheckedStatus && 1 <= reviewCount) {
        return `${name} has received ${reviewCount} client ${formatPlural(reviewCount, 'review', 'reviews')} in the past 12 months; ${reviewVerifiedPercent}% of these have been verified`;
    }

    return `${name} has not received any client reviews in the past 12 months`;
}

export function getTooltipAdviserType(type, name) {
    switch (type) {
        case FRIENDLY_NAME.FINANCIAL_COACH:
            return (
                <Typography variant='body2'>
                    A Financial Coach can provide guidance across all areas of your finances, helping you to improve your relationship with money, set goals, take action and overcome any obstacles in the way. Financial coaches are not regulated by the FCA and cannot recommend specific financial products.
                </Typography>
            );
        case 'Focussed Independent Adviser':
            return `${name} is a focussed Independent Financial Adviser, meaning ${name} focuses on certain investment and pension matters, as listed on their profile page. ${name} can provide advice across a sufficient range of products to meet their clients' needs in these specific areas, and is in no way limited to investment or pension products provided by companies ${name} works with.`;
        case FRIENDLY_NAME.IFA:
            return `${name} is an Independent Financial Adviser, meaning ${name} can advise across all investment and pension matters relevant to their clients. ${name} can provide advice across a sufficient range of products to meet their needs, and is in no way limited to investment or pension products provided by companies ${name} works with.`;
        case FRIENDLY_NAME.FA_RESTRICTED:
            return `${name} is a restricted Financial Adviser, meaning ${name} may advise across a limited set of investment and pension products, which may be provided by companies ${name} works with. This limitation may allow ${name} to offer an improved service quality and lower fees, but it’s important you understand how these products compare with others available.`;
        case FRIENDLY_NAME.MORTGAGE_ADVISER:
            return `${name} can advise across a wide range of mortgage products in the market. It is important to ask if ${name} checks lenders’ direct-only deals.`;
        case FRIENDLY_NAME.MORTGAGE_LIMITED:
            return `${name} can advise across a limited set of mortgage products, which may be provided by companies ${name} works with. This limitation may allow ${name} to offer an improved service quality and lower fees, but it’s important you understand how these products compare with others available.`;
        case FRIENDLY_NAME.PROTECTION_WHOLE_OF_MARKET:
            return `${name} is a whole of market Protection Adviser, meaning ${name} can advise across a wide range of insurance products in the market. It’s important to ask if ${name} checks providers’ direct only deals.`
        case FRIENDLY_NAME.PROTECTION_LIMITED:
            return `${name} is a limited Protection Adviser, meaning ${name} can advise across a limited set of insurance products, which may be provided by companies that ${name} works with. This limitation may allow ${name} to offer an improved service, but it’s important you understand how these products compare with others available.`
        case FRIENDLY_NAME.EQUITY_RELEASE_WHOLE_OF_MARKET:
            return `${name} is an Equity Release specialist, meaning ${name} can advise if releasing equity from your home would be right for you or not. ${name} is a Whole of Market adviser, meaning ${name} has access to a sufficient range of lenders who offer these plans, and is in no way limited to products provided by companies ${name} works with.`
        case FRIENDLY_NAME.EQUITY_RELEASE_LIMITED:
            return `${name} is an Equity Release specialist, meaning ${name} can advise if releasing equity from your home would be right for you or not. ${name} is a Limited adviser, meaning ${name} has access to a limited set of lenders who offer these plans, which may be provided by companies that ${name} works with. This limitation may allow ${name} to offer an improved service, but it’s important you understand how these products compare with others available.`;
        default:
            return '';
    }
}

export function getTooltipQualifications(hasCheckedStatus, name) {
    if (hasCheckedStatus) {
        return `${name} has added their qualifications to their profile. You can see them by clicking on the ‘qualifications’ tab below`;
    }

    return `${name} has not yet added their qualifications to their profile`;
}

export function getTooltipFeesUploaded(hasCheckedStatus, name) {
    if (hasCheckedStatus) {
        return `${name} has added their fees to their profile. You can see them by clicking on the ‘fees’ tab below`;
    }

    return `${name} has not yet added their fees to their profile`;
}

export function getTooltipFirstImpressions(hasCheckedStatus, name, count, percent) {
    const impressionPlural = formatPlural(count, 'impression', 'impressions');

    return `${percent}% of VouchedFor users have had a positive (4 or 5 star) first experience with ${name} (based on ${count} first ${impressionPlural})`;
}

export function getChecksCount(check) {
    if (!check) return 0;

    switch (check.type) {
        case 'recent_reviews':
            return _.get(check, ['data', 'more_info', 'count'], 0);
        case 'qualifications_uploaded':
            return _.get(check, ['data', 'count'], 0);
        case 'positive_first_impressions':
            return _.get(check, ['data', 'more_info', 'count'], 0);
        default:
            return 0;
    }
}

export function getProfileCheckStatus(check) {
    const data = _.get(check, 'data', null);
    const moreInfo = _.get(check, ['data', 'more_info'], {});

    if (data) {
        if ((data.name === 'Qualifications Uploaded' && !data.status) || (data.name === 'Transparent Fees' && !data.status) || data.status === 'pending' || (data.name === 'Positive First Impressions' && moreInfo.count === 0)) {
            return PROFILE_CHECK_PENDING;
        }

        if (data.status || check.data.date || (data.count && data.count !== 0) || (data.more_info && data.more_info.count !== 0 && data.status)) {
            return PROFILE_CHECK_PASS;
        }
    }

    return PROFILE_CHECK_FAIL;
}

export function getCheckItemSubText(type, status, name, reviewsPercentage, checkDate, count, positivePercentage, financialCoachText, professionalType) {
    if (!type) return '';

    if (type === 'fca') {
        if (status === 'pending') {
            if(professionalType == PROFESSIONAL_TYPE.FINANCIAL_COACH) {
                return `Last checked: N/A`;
            }
            return `This check is pending`;
        }

        return `Last checked: ${checkDate}`;
    }

    if (type === 'recent_reviews') {
        if (reviewsPercentage) {
            return `Verified reviews: ${reviewsPercentage}%`;
        }

        return `${name} has not received any reviews in the last 12 months`;
    }

    if (type === 'adviser_type') {
        return `Last checked: ${checkDate}`;
    }

    if (type === 'qualifications_uploaded') {
        if (count) {
            return `${count} qualification ${formatPlural(count, 'certificate', 'certificates')} available to view`;
        }

        return `Qualifications not yet added`;
    }

    if (type === 'fees_uploaded') {
        if (status) {
            return `${name} has provided information on their typical fees`;
        }

        return `Fees not yet added`;
    }

    if (type === 'positive_first_impressions') {
        if (count) {
            return `${positivePercentage}% of users who are not yet clients had a positive first experience with ${name}`;
        }

        return `${name} has not yet received any first impressions`;
    }
    if (type === 'accredited_or_trained_status') {
        return financialCoachText;
    }
}

export function getServiceLabelBy(type, vertical, key) {
    return _.get(getServiceBy(type, vertical, key), 'label', '');
}

export function getServiceBy(type, vertical, key) {
    let identifier = key;
    const acceptedTypes = ['id', 'gka'];

    if (!acceptedTypes.includes(type)) return null;

    if (type === acceptedTypes[0]) {
        identifier = Number(key);
    }

    const getMatchedService = services => {
        return _.find(services, service => service[type] === identifier) || null;
    };

    if (vertical === FINANCIAL_ADVISER_ID) {
        return getMatchedService(financial_adviser_services);
    }

    if (vertical === LEGAL_ADVISER_ID) {
        return getMatchedService(legal_adviser_services);
    }

    if (vertical === ACCOUNTANT_ID) {
        return getMatchedService(accountant_business_services);
    }

    return null;
}

export function getVerticalIdFromFirmType(type) {
    switch (type) {
        case 'accountant':
            return ACCOUNTANT_ID;
        case 'solicitor':
        case 'legal_adviser':
            return LEGAL_ADVISER_ID;
        case 'adviser':
        default:
            return FINANCIAL_ADVISER_ID;
    }
}

export function generateRatingLabels(key) {
    switch (key) {
        case 'accountant_quality_of_advice':
        case 'legal_adviser_quality_of_advice':
        case 'financial_adviser_quality_of_advice':
        case 'quality_of_advice':
            return 'Advice';
        case 'accountant_quality_of_service':
        case 'legal_adviser_quality_of_service':
        case 'financial_adviser_quality_of_service':
        case 'quality_of_service':
            return 'Service';
        case 'accountant_value_for_money':
        case 'legal_adviser_value_for_money':
        case 'financial_adviser_value_for_money':
        case 'value_for_money':
            return 'Value';
        default:
            return null;
    }
}

export function getFeesLabel(type) {
    const { INVESTMENT_AND_PENSIONS, FINANCIAL_PLANNING } = FEE_TYPES;

    switch (type) {
        case INVESTMENT_AND_PENSIONS:
            return 'Investments & Pensions (without Financial Planning)';
        case FINANCIAL_PLANNING:
            return 'Financial Planning with Investments & Pensions';
        default:
            return _.get(financial_adviser_services, ['financial_adviser_advice_areas_services_mortgages', 'label'], '');
    }
}

export function feesDropdownFilter(fees) {
    const { INVESTMENT_AND_PENSIONS, FINANCIAL_PLANNING } = FEE_TYPES;

    if (_.find(fees, fee => fee.entityType === FINANCIAL_PLANNING)) {
        return _.filter(fees, fee => fee.entityType !== INVESTMENT_AND_PENSIONS);
    } else {
        return fees;
    }
}

export function getProfileOffers(attributeId, name, pathname, professionalType, linkingVerb) {
    let getInitial;

    if (isMortgageAdviser(professionalType)) {
        getInitial = duration => [
            {
                name: `Free ${duration} Initial Consultation`,
                description: `Contact ${name} through VouchedFor and your initial ${duration} meeting or phone call will be free of charge. You can use this to discuss your situation and find out whether you feel they are a good match for you.`,
                buttonLabel: `Request Free ${duration} Initial Consultation`,
                analyticsLabel: `Offers(Free ${duration} Initial Consultation)`,
            }
        ];
    } else {
        getInitial = duration => [
            {
                name: `Free ${duration} Initial Consultation`,
                description: `Contact ${name} through VouchedFor and your initial ${duration} meeting or phone call will be free of charge. You can use this to discuss your situation and find out whether you feel they are a good match for you.`,
                buttonLabel: `Request Free ${duration} Initial Consultation`,
                analyticsLabel: `Offers(Free ${duration} Initial Consultation)`,
            },
            {
                name: 'Free General Financial Review',
                description: `Contact ${name} through VouchedFor and they will carry out a free general review of your finances including any savings and investments you have.`,
                buttonLabel: 'Request financial review',
                analyticsLabel: 'Offers(Free General Financial Review)',
            },
            {
                name: 'Free Pension review',
                description: `Contact ${name} through VouchedFor and they will carry out a free review of your pension planning.`,
                buttonLabel: 'Request pension review',
                analyticsLabel: 'Offers(Free Pension Review)'
            },
        ];
    }

    const getDiscount = amount => [{
        name: `£${amount} Fees Discount`,
        description: `If you choose to receive advice from ${name} after contacting them through VouchedFor then they will discount their fees by £${amount}.`,
        buttonLabel: `Contact ${name}`,
        analyticsLabel: `Offers(£${amount} Fees Discount)`,
    }];

    switch(attributeId) {
        case 415:
            return getInitial('30 minute');
        case 416:
            return getInitial('1 hour');
        case 417:
            return getDiscount('100');
        case 418:
            return getDiscount('200');
        case 624:
            return [{
                name: 'Pension Advice Vouchers',
                description: `Following new legislation, ${name} can help you claim a tax rebate from your employer on up to £500 worth of pension advice per year.`,
                buttonLabel: 'Get pension advice',
                analyticsLabel: 'Offers(Pension Advice Vouchers)',
            }];
        default:
            return null;
    }
}

export function getReviewTypeTitle(isRepeatReview, isFirstImpression, isFirmReview, firstName, lastName) {
    const hasName = Boolean(firstName) && Boolean(lastName);

    if (isRepeatReview) {
        if (isFirmReview && hasName) {
            return `Repeat review from verified client for ${firstName} ${lastName}`;
        }

        return 'Repeat review from verified client';
    }

    if (isFirstImpression) {
        if (isFirmReview && hasName) {
            return `VouchedFor user first impression for ${firstName} ${lastName}`
        }

        return 'First impression from a user who is not yet a client';
    }

    if (isFirmReview && hasName) {
        return `Review from verified client for ${firstName} ${lastName}`;
    }

    return 'Review from verified client';
}

export function getOrderedServicesFromIds(ids, verticalId) {
    return ids.map(serviceId => getServiceBy('id', verticalId, serviceId))
        .filter(service => Boolean(service))
        .sort((a, b) => a.popularity - b.popularity);
}

export function getProfessionalType(professional_type) {
    switch(professional_type){
        case PROFESSIONAL_TYPE.FINANCIAL_COACH :
            return 'financial coach';

        default:
            return 'adviser';
    }
}
